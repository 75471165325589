<template lang="pug">
.header.header-desktop.sticky
  //- NuxtLink(:to="isDefault ? '/' : ''")
  NuxtLink(to="/")
    img.w-28(src="/assets/images/logo/logo.webp" alt="logo")
  |
  .header-menu
    .header-menu_item.header-menu_item-dropdown-menu(v-if="isDefault")
      span Services
      |
      IconChevronDown.icon-chevron
      |
      .dropdown-menu
        NuxtLink.dropdown-menu-item(to="/sponsor_licence/") Sponsor Licence
        |
        NuxtLink.dropdown-menu-item(to="/skilled-worker-visa-application/") Skilled Worker Visa
        |
        NuxtLink.dropdown-menu-item(to="/sponsorship-compliance-platform/") Immigration Compliance and Audit
    |
    NuxtLink.header-menu_item(v-if="isDefault" to="/pricing/") Pricing
    |
    NuxtLink.header-menu_item(v-if="isDefault" to="/about-us/") About us
    |
    NuxtLink.header-menu_item(v-if="isDefault" to="/blog/") Blog
    |
    NuxtLink.header-menu_item.login(v-if="isDefault" to="https://compliance.nationbetter.uk/#/auth/login") Log in
    |
    NuxtLink(
      class="btn btn-outline btn-primary" 
      :class="{ 'btn-outline': header.button.outline }"
      :target="header.button.openInNewTab ? '_blank' : '_self'"
      :to="header.button.to" 
      @click="trackClick()"
    ) {{ header.button.label }}
|
.header.header-mobile.sticky
  .header-mobile-images
    IconMenu2.burger-menu(v-if="isDefault" @click="openMenu")
    |
    NuxtLink(to="/")
      img.ml-5(src="/assets/images/logo/logo.webp" alt="logo")
  |
  NuxtLink.ml-auto(
    class="btn btn-outline btn-primary" 
    :target="header.button.openInNewTab ? '_blank' : '_self'"
    :to="header.button.to" 
    @click="trackClick()"
  ) {{ header.button.label }}
  |
  .header-mobile-menu(v-if="isMenuOpen && isDefault" @click="closeMenu")
    IconX.close-icon
    |
    .header-menu_item
      span Services
      |
      //- IconChevronDown.icon-chevron
    |
    .flex.flex-col.bb.pb-8
      NuxtLink(to="/sponsor_licence") Sponsor Licence
      |
      NuxtLink(to="/skilled-worker-visa-application/") Skilled Worker Visa
      |
      NuxtLink(to="/sponsorship-compliance-platform/") Immigration Compliance and Audit
    |
    NuxtLink.header-menu_item.bb(to="/pricing/") Pricing
    |
    NuxtLink.header-menu_item.bb(to="/about-us/") About us
    |
    NuxtLink.header-menu_item.bb(to="/blog/") Blog
    |
    //- NuxtLink.header-menu_item.bb(to="https://compliance.nationbetter.uk/#/auth/login") Log in
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { IconChevronDown, IconMenu2, IconX } from '@tabler/icons-vue';
import type { THeader } from "@/models/page";

interface IProps {
  header?: THeader | null;
}

const props = defineProps<IProps>();

const header = computed(() => props.header || {
  type: "default",
  links: [],
  button: {
    label: "Sign Up",
    to: "/contact",
    openInNewTab: false,
    outline: true,
  },
});

const isDefault = computed(() => header.value.type === "default");

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const openMenu = () => {
  isMenuOpen.value = true;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

watch(isMenuOpen, (newVal) => {
  document.body.style.overflow = newVal ? 'hidden' : '';
});

const { $amplitude } = useNuxtApp();

const trackClick = () => {
  if (!$amplitude) return;
  $amplitude.track(`${header.value.button.label} Button Clicked`, {
    page: window.location.pathname,
    destination: header.value.button.to,
  });
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px 142px

  &.sticky
    position: sticky
    top: 0
    z-index: 1000
    background: #F8FAF2

  &-menu
    display: flex
    align-items: center
    gap: 32px

    &_item
      color: #034030
      font-size: 16px
      font-style: normal
      font-weight: 600
      line-height: 23px
      cursor: pointer
      transition: 0.2s
      border-bottom: 2px solid transparent
      display: flex
      align-items: center
      gap: 8px
      position: relative
      padding-top: 12px
      padding-bottom: 12px
      text-align: left

      &:hover
        border-bottom: 2px solid #034030

      &.login
        margin-left: 32px

    .icon-chevron
      transition: 0.2s

    .header-menu_item-dropdown-menu
      &:hover
        border-bottom: 2px solid transparent

      &:hover .dropdown-menu, .dropdown-menu:hover
        display: flex

      &:hover .icon-chevron
        transform: scaleY(-1)

.dropdown-menu
  display: none
  width: 300px
  flex-direction: column
  align-items: flex-start
  border-radius: 12px
  border: 1px solid var(--tealgreen-800, #074033)
  background: #FFF
  color: #000
  text-align: center
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: 23px
  position: absolute
  top: calc(100%)
  left: 0
  z-index: 10

  &-item
    display: flex
    padding: 10px 16px
    align-items: center
    gap: 10px
    align-self: stretch
    transition: 0.3s

    &:hover
      background: var(--darkilimegreen-50, #EBF0E1)

    &:first-child
      border-top-right-radius: 12px
      border-top-left-radius: 12px

    &:last-child
      border-bottom-right-radius: 12px
      border-bottom-left-radius: 12px

.header-desktop
  display: flex

.header-mobile
  display: none

@media (max-width: 1024px)
  .header
    padding: 20px 5vw

  .header-menu
    gap: 20px

    &_item
      font-size: 14px
      line-height: 20px

@media (max-width: 768px)
  .header
    align-items: center
    padding: 15px 20px
    color: var(--tealgreen-800, #074033)
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 220%

    & :deep(.btn)
      color: var(--tealgreen-800, #074033) !important

  .header-desktop
    display: none

  .header-mobile
    display: flex
    justify-content: space-between
    position: relative

    &-images
      display: flex
      align-items: center

    img
      height: 30px

    &-menu
      position: absolute
      top: 0
      left: 0
      right: 0
      height: 100vh
      background: var(--darkilimegreen-50, #EBF0E1)
      padding: 20px
      z-index: 1

      .close-icon 
        color: #537469
        margin-left: auto

      .header-menu_item
        font-weight: 600
        padding-top: 16px
        padding-bottom: 16px
      
      .bb
        border-bottom: 1px solid var(--darkilimegreen-200, #C6D2B1)

      .icon-chevron
        transform: scaleY(-1)
</style>